<template>
  <b-table
    :items="mainList.dataset"
    :fields="tableFields"
    :busy="onLoadingList"
    class="table-bordered table-hover"
  >
    <template v-slot:table-busy>
      <vcl-table :speed="5" :animate="true" :columns="10" />
    </template>
    <template v-slot:cell(index)="row">
      {{ row.index + 1 }}
    </template>
    <template v-slot:cell(createdAt)="row">
      <span style="display: block">{{ formatDate(row.item.createdAt) }}</span>
    </template>
    <template v-slot:cell(amount)="row">
      {{ convertPrice(row.item.amount) }}
    </template>
  </b-table>
</template>

<style>
.break-line {
  white-space: break-spaces;
}
</style>

<script>
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from './../../utils/apiUrl';
import moment from 'moment';

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};

export default {
  props: ['orderNumber'],
  components: {
    VclTable,
  },
  data() {
    return {
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      onLoadingList: false,
      tableFields: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'createdAt',
          label: 'Ngày giao dịch',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '13%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'typeName',
          label: 'Loại',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '16%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'amount',
          label: 'Số tiền',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '12%',
          },
          tdClass: 'text-right',
        },
        // {
        //   key: 'accountantId',
        //   label: 'Nợ',
        //   sortable: false,
        //   thStyle: {
        //     ...thStyleCommon,
        //     width: '12%'
        //   },
        //   tdClass: 'text-center'
        // },
        // {
        //   key: 'accountantRefId',
        //   label: 'Có',
        //   sortable: false,
        //   thStyle: {
        //     ...thStyleCommon,
        //     width: '12%'
        //   },
        //   tdClass: 'text-center'
        // },
        {
          key: 'note',
          label: 'Diễn giải',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'text-left break-line',
        },
      ],
    };
  },
  methods: {
    fetchMainData: async function () {
      // Init request header.
      this.onLoadingList = true;
      ApiService.get(
        cmdUrl.TransactionUrl.forOrder.replace('{number}', this.orderNumber)
      )
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch(() => {
          this.onLoadingList = false;
        });
    },
    formatDate: function (dateVal) {
      const date = moment(dateVal);
      return date.format('DD/MM/yyyy');
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
  },
  mounted() {
    this.fetchMainData();
  },
};
</script>
