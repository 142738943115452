<template>
  <div>
    <b-row>
      <b-col
        v-if="configHtml.colsType === 0 ? false : true"
        :cols="configHtml.colsType"
        :class="configHtml.classType"
      >
        <b-form-select
          class="select-style"
          v-model="productSearch.searchType"
          :options="[
            {
              id: 1,
              name: 'Tìm sản phẩm',
            },
            {
              id: 2,
              name: 'Tìm sản phẩm imei',
            },
          ]"
          size="sm"
          value-field="id"
          text-field="name"
          :disabled="isDisabled"
        ></b-form-select>
      </b-col>
      <b-col :cols="configHtml.colsProduct" :class="configHtml.classProduct">
        <treeselect
          :load-options="loadOptionProducts"
          :multiple="false"
          :async="true"
          :placeholder="productSearch.placeHolder"
          :cache-options="false"
          retryText="Thử lại..."
          retryTitle="Nhấp thử lại"
          searchPromptText="Nhập để tìm kiếm..."
          loadingText="Đang tải..."
          noResultsText="Không có kết quả"
          openDirection="bottom"
          @select="onSelectProduct"
          :always-open="isOpenProductSearch"
          @search-change="onSearchChange"
          :disabled="isDisabled"
        >
          <label
            slot="option-label"
            slot-scope="{ node, labelClassName }"
            :class="labelClassName"
          >
            <template>
              <div>
                <b class="text-primary"
                  >( {{ convertPrice(node.raw.sellingPrice) }} )</b
                >
                <b
                  :class="
                    node.raw.quantityInStock > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  ( TCB:{{ node.raw.quantityInStock }} )
                </b>

                {{ node.raw.label }}
                <span
                  v-if="
                    node.raw.imeiCode && node.raw.imeiCode.trim().length > 0
                  "
                  class="text-warning"
                >
                  - IMEI:
                  {{ node.raw.imeiCode }}
                </span>
              </div>
            </template>
          </label>
        </treeselect>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { checkPermissions } from '../../../utils/saveDataToLocal';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import { PRODUCT_TYPE, ITEM_TYPE } from '@/utils/enum';
import { convertPrice } from '@/utils/common';
import ApiService from '@/core/services/api.service';

const SEARCH_TYPE = {
  PRODUCT: 1,
  IMEI: 2,
};

export default {
  props: ['searchType', 'storeId', 'orderItems', 'mode', 'product'],
  mixins: [validationMixin],
  data() {
    return {
      isOpenProductSearch: false,
      isProcessing: false,
      productSearch: {
        searchType: SEARCH_TYPE.PRODUCT,
        placeHolder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        searchName: null,
      },
      configMode: {
        gift: {
          colsType: 3,
          colsProduct: 9,
          classType: 'pr-0',
          classProduct: 'pl-0',
        },
        common: {
          colsType: 3,
          colsProduct: 9,
          classType: 'pr-0',
          classProduct: 'pl-0',
        },
        parent: {
          colsType: 3,
          colsProduct: 9,
          classType: 'pr-0',
          classProduct: 'pl-0',
          requiredField: ['product'],
        },
        product: {
          colsType: 0,
          colsProduct: 12,
          classType: '',
          classProduct: '',
        },
      },
      productSearchTemp: null,
      configHtml: null,
      isDisabled: false,
    };
  },
  components: {
    Treeselect,
  },
  computed: {},
  created() {
    this.initialData();
  },
  watch: {
    /*  $props: {
      handler() {},
      deep: true,
      immediate: true,
    }, */
  },
  validations: {},
  methods: {
    convertPrice,
    checkPermissions,
    initialData() {
      this.configHtml = this.configMode.common;

      if (this.searchType) {
        this.productSearch.searchType = this.searchType;
      }
    },
    async loadOptionProducts({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        this.productSearchTemp = searchQuery;
        const type = this.productSearch.searchType;
        this.productSearchTemp = searchQuery;
        const result = await this.searchProductAPI(searchQuery, type);

        const optionProduct = (result.data || []).map((element) => {
          if (type === PRODUCT_TYPE.PRODUCT) {
            return {
              id: this.$uuid.v4(),
              label: element.productName,
              ...element,
            };
          } else {
            return {
              id: this.$uuid.v4(),
              label: element.productName,
              imeiCode: element.imeiCode,
              ...element,
            };
          }
        });

        callback(null, optionProduct);
      }
    },
    searchProductAPI(searchQuery, type) {
      let products = [];
      switch (type) {
        case SEARCH_TYPE.PRODUCT:
          products = this.fetchProduct(searchQuery, type);
          break;
        case SEARCH_TYPE.IMEI:
          products = this.fetchProductImei(searchQuery, type);
          break;
        default:
          break;
      }
      return products;
    },
    fetchProduct(textSearch) {
      const params = {
        searchProduct: textSearch ? textSearch.trim() : null,
        storeId: this.storeId,
      };

      return ApiService.query('productSearch', { params }).then((response) => {
        return response.data;
      });
    },
    fetchProductImei(textSearch) {
      const params = {
        imeiCode: textSearch ? textSearch.trim() : null,
        storeId: this.storeId,
      };

      return ApiService.query(`productSearch/search-by-imei`, { params }).then(
        (response) => {
          return response.data;
        }
      );
    },
    onSelectProduct(node) {
      this.isOpenProductSearch = false;
      const item = this.handleOrderItemSelected(node);
      switch (this.mode) {
        case 'parent': {
          const parent = this.product;
          if (parent.childs) {
            parent.childs.push(item);
          } else {
            parent.childs = [item];
          }
          this.$emit('selected', parent);
          break;
        }
        default:
          this.$emit('selected', item);
          break;
      }
    },
    onSearchChange(searchQuery) {
      if (searchQuery.length > 1 && !this.isOpenProductSearch) {
        this.isOpenProductSearch = true;
      } else if (searchQuery.length === 0) {
        this.isOpenProductSearch = false;
      }
    },
    handleOrderItemSelected(item) {
      item.orderItemType = ITEM_TYPE.MAIN;
      item.quantity = 1;
      item.isGiftTaken = true;
      item.discountType = 1;
      item.discountAmount = 0;
      item.repurchasePrice =0;
      item.sellingPriceMask = item.sellingPrice;
      return item;
    },
  },
};
</script>
